import { useEffect, useState } from "react";
import Nav from "./include/Nav";
import axios from "axios";
import axiosInstance from "../axiosInstance";

function FAQ({ dark }) {
  const [api, setapiData] = useState([]);
  const [data, setData] = useState([]);
  const [accor, setAccor] = useState();
  const API =
    "/api/getfaqs";
  useEffect(() => {
    axiosInstance
      .get(API)
      .then((res) => {
        setData(res.data.faqs);
        setapiData(res.data.faqs)
        console.log(res.data.faqs);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleSearch=(val)=>{
    if(val==""){setData([...api]) 
      return}
    const result=api.filter((data,i)=>(data.questions.toLowerCase().includes(val.toLowerCase())||data.answers.toLowerCase().includes(val.toLowerCase())))
setData(result)
  }
  return (
    <div>
      {/* <Nav /> */}
      <div class="p-4 sm:mx-64 ">
        <div class="p-4 rounded-lg dark:border-gray-700 mt-14">
          <div class="md:flex justify-stretch gap-4 mb-4">
            <div class=" items-center md:w-full rounded">
              <h2
                style={{}}
                class={`text-center text-4xl mb-8 ${
                  dark
                    ? "custom-bg text-white hover:text-white"
                    : "text-gray-800 hover:text-gray-800"
                } font-bold  dark:text-gray-500`}
              >
                Questions ? Look here.
              </h2>
              {/* <p>
                Can't find an answer? call us at (00)000-0000 or email
                abc@gmail.com
              </p> */}

              <div className="relative rounded my-2">
                <div class="flex absolute justify-center  content-center  p-3  ">
                  <svg
                   class = "justify-center  content-center "
                    style={{ width: "20px", color: "gray" }}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  class="block w-full py-2 ps-10 text-md text-gray-900 border  rounded bg-gray-50 focus:ring-blue-500 focus:border-blue-500  "
                  placeholder="Search..."
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              {data?.map((element, index) => {
                  return (
                    // <div className="mt-4">
                    //   <div className="flex items-center gap-4">
                    //     <p className="font-semibold text-indigo-600">
                    //       {" "}
                    //       <svg
                    //         xmlns="http://www.w3.org/2000/svg"
                    //         fill="none"
                    //         viewBox="0 0 24 24"
                    //         stroke-width="1.5"
                    //         stroke="currentColor"
                    //         class="w-6 h-6"
                    //       >
                    //         <path
                    //           stroke-linecap="round"
                    //           stroke-linejoin="round"
                    //           d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                    //         />
                    //       </svg>
                    //     </p>
                    //     <div>
                    //       <p class="text-md  py-1 font-semibold text-indigo-600 ">
                    //         {element.questions}
                    //       </p>
                    //       <p
                    //         dangerouslySetInnerHTML={{
                    //           __html: element.answers,
                    //         }}
                    //         class="text-sm  text-gray-800 dark:text-gray-500"
                    //       >
                    //         {}
                    //       </p>
                    //     </div>
                    //   </div>
                    //   <hr className="my-10" />{" "}
                    // </div>
                    <div
                      id="accordion-flush"
                      data-accordion="collapse"
                      data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
                      data-inactive-classes="text-gray-500 dark:text-gray-400"
                    >
                      <div
                        onClick={() =>
                          accor == index ? setAccor() : setAccor(index)
                        }
                        key={index}
                      >
                        {" "}
                        <h2 id="accordion-flush-heading-1">
                          <button
                            type="button"
                            class="flex items-center gap-4 justify-start w-full py-5 font-medium rtl:text-right text-black border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 gap-3"
                            data-accordion-target="#accordion-flush-body-1"
                            aria-expanded="true"
                            aria-controls="accordion-flush-body-1"
                          >
                            {accor != index ? (
                              <a>
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class={`w-6 h-6 ${
                                    dark ? "text-white" : "text-indigo-600"
                                  }`}
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 4.5v15m7.5-7.5h-15"
                                  />
                                </svg>
                              </a>
                            ) : (
                              <a>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class={`w-6 h-6 ${
                                    dark ? "text-white" : "text-indigo-600"
                                  }`}
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M5 12h14"
                                  />
                                </svg>
                              </a>
                            )}

                            <span
                              dangerouslySetInnerHTML={{
                                __html: element.questions,
                              }}
                              className={`${
                                dark ? "text-white" : "text-indigo-600"
                              }`}
                            ></span>
                          </button>
                        </h2>
                      </div>

                      {accor == index ? (
                        <>
                          {" "}
                          <div
                            id="accordion-flush-body-1"
                            class=""
                            aria-labelledby="accordion-flush-heading-1"
                          >
                            <div class="py-5 border-b border-gray-200 dark:border-gray-700">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: element.answers,
                                }}
                                class={`mb-2 ${
                                  dark ? "text-white" : "text-gray-500"
                                }  dark:text-white`}
                              ></p>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
