import { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";

function Side({
  data,
  dark,
  withoutHead,
  apiData,
 heading,
 subheading,
  active,
  searchValue,
  
}) {

  const [open, setOpen] = useState();
  const [subhead, setSubhead] = useState();
  const [currData, setCurrData] = useState(data);
  

    // const handleClick = (e,text) => {
    //   e.preventDefault();
    //   console.log(window.location.href,text,'txt');
    // // location.pathname = `${location.pathname}/${text}`;;
    // }
    const handelSearch = (e) => {
      let apiFilterData = [...apiData];
      console.log("Data-->>",apiFilterData);
      const result1 = apiFilterData.filter((element) => {
   
         if (e.target.value === "") {
          element.isOpen=false;
                // doc.isOpen=false
           return true;
         } else if (!(element?.heading?.toLowerCase().includes(e.target.value.toLowerCase()))) {
          element.isOpen=true;
           const doc = element?.docs?.filter((doc) => {
     
             if (doc?.main_heading?.toLowerCase().includes(e.target.value.toLowerCase())) {
         
              doc.isOpen=false
               return true;
             } 
           
           else {
               const sub = doc?.sub_docs?.filter((sub) => {
                sub.isOpen=false;
                // doc.isOpen=false
                // element.isOpen=false;
                 if (sub?.heading?.toLowerCase().includes(e.target.value.toLowerCase())) {
                 
              doc.isOpen=true
              sub.isOpen=true
                   return true;
                 }
              //    sub.isOpen=false;
              //    element.isOpen=false;
              // doc.isOpen=false
                 return false;
               });
               return sub ? sub.length > 0 : null
             }
           
           });
           return doc ? doc.length > 0 : null
         }
         else{
   
           return true;
         }
       });
       
       console.log("vv",e.target.value);
       setCurrData(result1);
      
    
      
     };
  useEffect(() => {
    console.log("act", active);
    setOpen(active);
  }, [active]);

  return (
    <div>
      <div
        class={`h-screen p-1 pb-16  overflow-scroll 
        ${dark ? "custom-bg" : "bg-white hover:text-black"} dark:bg-gray-800`}
      >
        <ul class="space-y-2  mb-8">
        <li>
                        <div class="relative mt-1 ms-4">
                          <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-2 pointer-events-none">
                            <svg
                              class="w-4 h-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                              />
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="search"
                          value={searchValue}
                            class="block p-1 ps-8 w-40 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Find anything"
                            onChange={(e) => handelSearch(e)}
                          />
                        </div>
                      </li>

          {withoutHead?.length && withoutHead?.map((element, i) => {
            if (element.heading_id) {
              return null;
            }

            return (
              <li

                  className={`flex text-sm items-center ${
                    dark
                      ? "custom-bg text-white  hover:text-black"
                      : " text-gray-500 "
                  } rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
                  // href={`#${element.main_heading.toLowerCase().replace(" ","_")}`}
                  //onClick={handleClick(element.main_heading.toLowerCase().replaceAll(" ","_"))}
                >
                  <span class="ms-2 my-5">{element.main_heading}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="min-w-[20px] min-h-[20px] max-w-[20px] max-h-[20px]"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
          
              </li>
            );
          })}
          {currData?.length ? currData?.map((element, index) => {
        // console.log("=>",element);
            return (
              <li
                className="my-[20px] navl"
                       
              >


              <NavLink 
                   onClick={(e) => {
                    (element.isOpen == true ? setCurrData(currData.map((data,i)=>{ if(i==index)data.isOpen=false; return data })): setCurrData(currData.map((data,i)=>{ if(i==index)data.isOpen=true; return data })));
                   // handleClick(e,element.heading.toLowerCase().replaceAll(" ","_"));
                  }}
                
               
                className={`flex active justify-between font-[400] px-2 text-sm mb-3 items-start text-bold ${
                  dark
                    ? "custom-bg text-white hover:text-black"
                    : " text-[#101010]"
                } rounded-lg  hover:bg-gray-100 dark:hover:bg-gray-700 group`}
              
              >


              
                {" "}
    
    
                  {element.heading}
            
              
                  {element.isOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="min-w-[20px] min-h-[20px] max-w-[20px] max-h-[20px]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="min-w-[20px] min-h-[20px] max-w-[20px] max-h-[20px] -rotate-90"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  )}
             </NavLink>

                {element.isOpen
                  ? element.docs?.map((doc, i) => {
                      console.log(i, "p3");
                      return (
                        <>
                          {" "}
            
        
                          <NavLink ac style={heading?.toLowerCase().replaceAll("_"," ")==doc.main_heading.toLowerCase()?{ backgroundColor:'#5f4cfe1A',padding:'5px 8px', color:'#5469D4',fontWeight:'600'}:{}} 
                            key={i}
                           onClick={() =>
                            (doc.isOpen == true ? setCurrData(currData.map((data,j)=>{ if(j==index)data.docs[i].isOpen=false; return data })): setCurrData(currData.map((data,j)=>{ if(j==index)data.docs[i].isOpen=true; return data })))
                          }
                          className={`flex text-sm items-center ml-5 mt-2 px-2 ${
                            dark
                              ? "custom-bg text-white  hover:text-black"
                              : " text-gray-500 "
                          } rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
                          to = {`/home/${doc.main_heading.toLowerCase().replaceAll(" ","_")}`}
                //  onClick={(e) => {
                //   handleClick(e,element.heading.toLowerCase().replaceAll(" ","_"))}}
                >
                   {doc.main_heading}
                   </NavLink>

                 {doc.isOpen ?
                 
                 doc.sub_docs?.map((ele, j) => {
                              
                                return (
                                  <>
                    
                <NavLink    key={j}   className={`flex text-sm items-center ml-8 mt-2 px-2 ${
                                        dark
                                          ? "bg-black text-white  hover:text-black"
                                          : " text-gray-500 "
            } rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`} style={subheading?.toLowerCase().replaceAll("_"," ")==ele.heading.toLowerCase()?{ color:'#5469D4',fontWeight:500 }:{}} 
            to = {`/home/${doc.main_heading.toLowerCase().replaceAll(" ","_")}/${ele.heading.toLowerCase().replaceAll(" ","_")}`}
    
                >
                   {ele.heading}
                   </NavLink>
                                  </>
                                );
                              })
                              : null
                            }
                        </>
                      );
                    })
                  : null}
              </li>
            );
          }):null
        }
        </ul>
      </div>
    </div>
  );
}

export default Side;
