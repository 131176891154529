import Nav from "./include/Nav";
import Side from "./include/Side";

import { useEffect, useState,useRef  } from "react";

// import { useNavigate } from "react-router-dom";
import {NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "./include/Footer";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

function Dashboard({
  data,
  dark,
  withoutHead,
  setShowNavbar,
  setData,
  setWithouthead,
  apiData,
  lang,
  setOpenSide,
  setApiData,
}) {
  setShowNavbar(true);

  // const location = useLocation();

  //selecting first tech for displaying the content
  

const location=useLocation()
const {heading,subheading}=useParams()
  
const leftContent = document.getElementById('left-content');
  useEffect(() => {
    setOpenSide(false)
      if(!heading&&!subheading)return
      
      const element = document.getElementById(subheading||heading);
      // console.log(section,element,cur,'ekee');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
     const res= data?.map((d)=>{
    d.docs=  d.docs.map((e)=>{
        if(e.main_heading.toLowerCase()==heading.toLowerCase().replaceAll("_"," ")){
         
          e.isOpen=true
          d.isOpen=true
        }
        e.sub_docs=subheading?e.sub_docs.map((s)=>{
          // console.log("sd11-",s.heading.toLowerCase().replaceAll("_"," "),"==>" s.he);
          if((s.heading.toLowerCase()==subheading.toLowerCase()||s.heading==subheading.toLowerCase().replaceAll("_"," "))){
        //  alert("enter")
       
            e.isOpen=true
            s.isOpen=true
            d.isOpen=true
          }
          return s
        }):e.sub_docs
        return e;
      })
    return d
      })
      setData(res)
    
  }, [location.pathname]);

  const [activeLang, setActiveLang] = useState(lang);
  const [text, setText] = useState("");
  const [clicked, setClicked] = useState(null);
  const [overflow,setOverflow] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
 

  {console.log(activeLang,'ac');}
  const {id}=''
 
  const [currSideData,setcurrSideData]=useState(id)
  const [curSection, setCurrentSection] = useState('');
   const [searchValue,setSearchValue]=useState()
  const targetRef = useRef(null);
  useEffect(()=>{
    console.log("enter",id);
    setcurrSideData(id)},[id])


  // const updateText = (textt) => {
  //   console.log(textt, "textt");
  //   setText(textt);
  // };
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const section = document.getElementById('api_description');
  //     console.log(section?.offsetTop,window.scrollY,isSticky ,'stickss');
  //     if (window.scrollY > section?.offsetTop && (section?.offsetTop + (section?.offsetTop / 2)) > window.scrollY ) {

  //       setIsSticky(true);
  //     } else {
  //       setIsSticky(false);
  //     }
  //   };
  //   console.log(isSticky,'stick');
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);




  const handleCopy = async (index,docindex, i) => {

    let content = data[index].docs[docindex].tech_headings[i].tech_contents.filter(
      (d) => activeLang === d.tech_id
    )[0]?.tech_content 
    
    if(!content){
    content = withoutHead[index].tech_headings[i].tech_contents.filter(
      (data) => activeLang == data.tech_id
    )[0]?.tech_content 
    }
    let parsedContent = "";
    const tempElement = document.createElement("div");
    tempElement.innerHTML = content;
    try {
      // const copiedText = document.get;
      await navigator.clipboard.writeText(tempElement.innerText);
      console.log(tempElement.innerText, "copiedText");
      // alert("Copied to clipboard!");
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
      // alert("Copy to clipboard failed.");
    }
  };

  const handleCopy1 = async (index, docindex, i,tch) => {
    console.log(index,'index');
    console.log(docindex,'index');
    console.log(i,'index');
   
console.log(data[index].docs[docindex].sub_docs[i].tech_headings[tch]);
    let content = data[index].docs[docindex].sub_docs[i].tech_headings[tch].tech_contents.filter(
      (data) => activeLang == data.tech_id
    )[0].tech_content;

    let parsedContent = "";
    const tempElement = document.createElement("div");
    tempElement.innerHTML = content;
    try {
      // const copiedText = document.get;
      await navigator.clipboard.writeText(tempElement.innerText);
      console.log(tempElement.innerText, "copiedText");
      // alert("Cop ied to clipboard!");
    } catch (err) {
      // console.error("Unable to copy to clipboard.", err);
      // alert("Copy to clipboard failed.");
    }
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    
    <div className={` ${
      dark ? "custom-bg" : "bg-white"
    } `}>
    
      <aside
        id="logo-sidebar"
        class={`fixed top-0 left-0 z-40 w-64 min-h-full  mb-106 mt-16 transition-transform -translate-x-full  ${
          dark ? "custom-bg" : "bg-white"
        } border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`}
        aria-label="Sidebar"
      >
      <Side
          data={data}
          setData={(value,e)=>{setSearchValue(value);setData(e)}}
          withoutHead={withoutHead}
          setWithouthead={setWithouthead}
          apiData={apiData}
          active={currSideData}
          setApiData={setApiData}
          dark={dark}
          heading={heading}
          subheading={subheading}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
         
        />
      </aside>



      <div
        class={`p-4 min-h-screen sm:ml-64 ${
          dark ? "custom-bg text-white" : "bg-white"
        }`}
      >
        <div class="p-4 rounded-lg dark:border-gray-700">
          
          {withoutHead?.map((element, index) => {
            {console.log(element,'element')}
            if (element.heading_id) {
              return null;
            }

            return (
              <>
              <Element name={element.main_heading.toLowerCase().replaceAll(" ","_")} className="scroll-m-20 md:flex mt-8  justify-evenly">
           
        

               
             
                <div
               
                 class=" items-center md:w-2/3 h-full rounded">
                  <h2
                  //  onClick={()=>nav(`/home/${element.main_heading}`)}
                     
                  // onClick={()=>nav(`/home/${element.main_heading.toLowerCase().replaceAll(" ","_")}`)}
                    class={`text-2xl pointer-events-none${
                      dark ? "custom-bg text-white" : " text-gray-800 "
                    } font-bold hover:text-gray-800 hover:underline dark:text-gray-500`}
                  >
                    {element.main_heading }
                  </h2>

                  <p
                    dangerouslySetInnerHTML={{ __html: element.content }}
                    class={`text-sm py-2 mr-8 ${
                      dark ? "custom-bg text-white" : " text-gray-800 "
                    } dark:text-gray-500`}
                  >
                    {}
                  </p>
                </div>
                </Element>
                <div class={`items-center h-full  overflow-hidden  md:w-1/2 rounded sticky-div`}
                          >
                  {element.tech_headings?.map((head, i) => {
                   
                    return (
                      <div className=''>
                        <div className="flex mt-4  text-white justify-between sm:flex-col sm rounded-t-lg bg-gray-500 p-2">
                          <p className="text-sm text-semibold"
                          
                          >
                            {head.heading}
                          </p>
                          {
                            <div className="flex gap-2">
                              <select
                                onClick={(e) => setActiveLang(e.target.value)}
                                className="bg-gray-500 border rounded text-sm"
                              >
                                {" "}
                               
                                {head.tech_contents?.map((ele2, j) => (
                                     <>
                                     { console.log(ele2.technologies?.id,i,"I3")}
                             
                                  <option
                                    value={ele2.technologies?.id}
                                    selected={
                                      activeLang == ele2.technologies?.id
                                        ? true
                                        : false
                                    }
                                  >
                                    {ele2.technologies?.technology_name}
                                  </option>
                               
                                  </>
                                ))}
                            
                              </select>

                              <div class="group relative  flex justify-center">
                                <button
                                  class="rounded  text-sm text-white shadow-sm"
                                  onClick={
                                    () =>{ handleCopy(index, i); setClicked(index)}
                                    
                                  }
                                >
                                  {clicked == index ? (
                                    <span class="absolute right-20 scale-0 rounded bg-white p-2 text-xs text-black group-hover:scale-100">
                                      copied
                                    </span>
                                  ) : (
                                    <span class="absolute  right-20 scale-0 rounded bg-white p-2 text-xs text-black group-hover:scale-100">
                                      click to copy
                                    </span>
                                  )}

                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          }
                        </div>
                        {console.log(head,"ele322")}
                        {/* {head.tech_contents?.map((ele3, k) => {
                       
                          return k == 0 ? (
                            <>
                        
                              <p
                                id="editor"
                                // dangerouslySetInnerHTML={{
                                //   __html: ele3.tech_content,
                                // }}
                                // value={text}
                                onChange={(e) => setText(ele3.tech_content)}
                                className="text-white text-nowrap  overflow-x-auto mb-4 bg-gray-600 rounded-b-lg text-sm p-2"
                              >
                                <pre id={"static-id" + k}>
                                  {parseContent(ele3.tech_content)}
                                </pre>
                              </p>
                            </>
                          ) : null;
                        })} */}
                      </div>
                    );
                  })}
                </div>
                {/* <>
                  <hr className="my-12" />{" "}
                </> */}
          
              </>
            );
          })}

          {data?.map((element, index) => {
           
              {console.log(element,'id22')}
            return (
              <>
                {" "} 
                {element.docs?.map((doc, docindex) => {
                  
                  return (
                    <>
                      {" "}
                      <div
                        data-id =  { doc.main_heading.toLowerCase().replaceAll(" ","_")}
                         id = { doc.main_heading.toLowerCase().replaceAll(" ","_")}
                        className="scroll-m-20 md:flex mt-8  justify-evenly pb-8" style={{borderBottom:"1px solid #ddd"}}
                      >
                        <>
                          <div 
                         
                           class=" items-center md:w-2/3  rounded">
                            {/* {console.log(element)} */}

                            <>
                            
                            <NavLink  to={`/home/${doc.main_heading.toLowerCase().replaceAll(" ","_")}`} className="text-sm underline text-semibold self-center px-2"> 
                             <h2
                              
                              // onClick={()=>nav(`/home/${doc.main_heading.toLowerCase().replaceAll(" ","_")}`)}
                              class={`text-2xl underline ${
                                dark
                                  ? "custom-bg text-white"
                                  : " text-gray-800 "
                              } font-bold hover:text-gray-800 hover:underline dark:text-gray-500`}
                              >
                                {doc.main_heading}
                              </h2>{" "}
                              </NavLink>
                              {/* {doc.techdings?.map((head,1)=>
                      return())} */}
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: doc.content,
                                }}
                                class={`text-sm py-2 mr-8  ${
                                  dark
                                    ? "custom-bg text-white"
                                    : " text-gray-800 "
                                } dark:text-gray-500`}
                              ></p>
                            </>
                          </div>
                          { console.log(doc,"heaid")}
                          <div class={`items-center h-full  overflow-hidden  md:w-1/2 rounded sticky-div`}
                          >
                            {doc.tech_headings?.map((head, i) => {
                            
                              return (
                                <div className={''} key={i}>
                                  {" "}
                                  {head.tech_contents[0]?.technologies !=
                                  null ? (
                                    
                                    <div className="flex mt-8 text-white flex-wrap sm:flex-nowrap justify-between rounded-t-lg bg-gray-500">
                                      <p className="text-sm text-semibold self-center px-2">
                                        {head.heading }
                                      </p>

                                      <div className="sm:flex   sm:flex-wrap py-2 sm:px-2 sm:justify-evenly">
                                        <select
                                          onClick={(e) =>
                                            setActiveLang(e.target.value)
                                          }
                                          className="bg-gray-500 border rounded text-sm mx-2 p-1"
                                        >
                                          {" "}
                                          {head.tech_contents?.map(
                                            (ele2, j) => (
                                              <option
                                                value={ele2.technologies?.id}
                                                selected={
                                                  activeLang ==
                                                  ele2.technologies?.id
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {
                                                  ele2.technologies
                                                    ?.technology_name
                                                }
                                              </option>
                                            )
                                          )}
                                        </select>
                                        <div class="group   flex justify-center mt-2 sm:mt-0">
                                          <button
                                            class="rounded relative text-sm text-white shadow-sm"
                                            onClick={
                                              () =>
                                               { handleCopy(index, docindex, i)
                                                setClicked(index)
                                              }
                                             
                                            }
                                          >
                                            {clicked == index ? (
                                              <span class="absolute right-[20px] top-[-35px] z-50  scale-0 rounded bg-white p-2 text-xs text-black group-hover:scale-100">
                                                Copied!
                                              </span>
                                            ) : (
                                              <span class="absolute  right-[20px] z-50  top-[-35px] w-[100px] whitespace-nowrap scale-0 rounded bg-white p-2 text-xs text-black group-hover:scale-100">
                                                click to copy 
                                              </span>
                                            )}

                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="currentColor"
                                              class="w-6 h-6"
                                            >
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                   
                                    <div className="flex mt-8 text-white justify-between rounded-t-lg bg-gray-400 p-2">
                                      <p className="text-sm text-semibold">
                                        {head.heading}
                                      </p>
                                    </div>
                                 

                               
                                  )}
                        
                                 
                                 
                                    
                                    {head.tech_contents?.map((ele3, k) => {
                                       console.log(ele3,"hii");
                                        return (
                                          (head.tech_contents[0]?.technologies !=
                                            null ? activeLang ==
                                              ele3.technologies?.id:true)&& (
                                            <p
                                            key={k}
                                              id="editor"
                                              // dangerouslySetInnerHTML={{
                                              //   __html: ele3.tech_content,
                                              // }}
                                              value={text}
                                              onChange={(e) =>
                                                setText(e.target.value)
                                              }
                                              onClick={()=>{setOverflow(index)}}
                                              className={`text-white text-nowrap ${overflow == index ? 'overflow-x-auto' : ''  } mb-4 bg-gray-600 rounded-b-lg text-sm p-2`}
                                            >
                                              <pre className="codeEditor">
                                                {/* {parseContent( 
                                                  ele3.tech_content
                                               )} */}
                                                 <div dangerouslySetInnerHTML={{__html: ele3.tech_content}}></div>
                                              </pre>
                                            </p>
                                          )
                                        );
                                        
                                      })


                                   
                                      }
                                 
                                </div>
                              );
                            })}
                          </div>
                          
                        </>

                        <>
                          <hr className="my-12" />{" "}
                        </>
                      </div>
                      {doc.sub_docs?.map((ele1, k) => {
                     
                        return (
                          <div
                            data-id = {ele1.heading.toLowerCase().replaceAll(" ","_")}
                            id = {ele1.heading.toLowerCase().replaceAll(" ","_")}
                            className="scroll-m-20 md:flex mt-8  justify-evenly pb-8" style={{borderBottom:"1px solid #ddd"}} 
                            >
             
                            <div class=" items-center md:w-2/3  rounded">
                              {/* {console.log(element)} */}

                              <>
                              <NavLink  to={`/home/${doc.main_heading.toLowerCase().replaceAll(" ","_")}/${ele1.heading.toLowerCase().replaceAll(" ","_")}`} className="text-sm underline text-semibold self-center px-2">
                                <h2
                                  class={`text-2xl underline ${
                                    dark
                                      ? "custom-bg text-white"
                                      : " text-gray-800 "
                                  } font-bold hover:text-gray-800 hover:underline dark:text-gray-500`}
                                  // onClick={()=>nav(`/home/${ ele1.heading.toLowerCase().replaceAll(" ","_")}`)}
                                >
                                  {ele1.heading}
                                </h2>
                                </NavLink>
                                {" "}
                                {/* {doc.techdings?.map((head,1)=>
                          return())} */}
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: ele1.content,
                                  }}
                                  class={`text-sm  mr-8 py-2 ${
                                    dark
                                      ? "custom-bg text-white"
                                      : " text-gray-800 "
                                  } dark:text-gray-500`}
                                ></p>
                              </>
                            </div>
                            <div class={`items-center h-full  overflow-hidden  md:w-1/2 rounded sticky-div`}
                          >
                            {/* <div class="items-center overflow-hidden  md:w-1/2 rounded sticky-div"> */}
                            {ele1.tech_headings?.map((head, i) => {
                            
                            return (
                              <div className='' key={i}>
                                {" "}
                                {head.tech_contents[0]?.technologies !=
                                null ? (
                                  <div className="flex mt-8 text-white flex-wrap sm:flex-nowrap justify-between rounded-t-lg bg-gray-500">
                                    <p className="text-sm text-semibold self-center px-2">
                                      {head.heading}
                                    </p>

                                    <div className="sm:flex   sm:flex-wrap py-2 sm:px-2 sm:justify-evenly">
                                      <select
                                        onClick={(e) =>
                                          setActiveLang(e.target.value)
                                        }
                                        className="bg-gray-500 border rounded text-sm mx-2 p-1"
                                      >
                                        {" "}
                                        {head.tech_contents?.map(
                                          (ele2, j) => (
                                            <option
                                              value={ele2.technologies?.id}
                                              selected={
                                                activeLang ==
                                                ele2.technologies?.id
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {
                                                ele2.technologies
                                                  ?.technology_name
                                              }
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <div class="group   flex justify-center mt-2 sm:mt-0">
                                        <button
                                          class="rounded relative text-sm text-white shadow-sm"
                                          onClick={
                                            () =>
                                             { handleCopy1(index, docindex, k,i)
                                              setClicked(index)
                                            }
                                           
                                          }
                                        >
                                          {clicked == index ? (
                                            <span class="absolute right-[20px] top-[-35px] z-50  scale-0 rounded bg-white p-2 text-xs text-black group-hover:scale-100">
                                              Copied!
                                            </span>
                                          ) : (
                                            <span class="absolute  right-[20px] z-50  top-[-35px] w-[100px] whitespace-nowrap scale-0 rounded bg-white p-2 text-xs text-black group-hover:scale-100">
                                              click to copy 
                                            </span>
                                          )}

                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-6 h-6"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ) : 
                                
                                
                                (
                                  
                                  <div className="flex mt-8 text-white justify-between rounded-t-lg bg-gray-400 p-2">
                                    <p className="text-sm text-semibold">
                                      {head.heading}
                                    </p>
                                  </div>
                                )}
                      
                               
                               
                                  
                                  {head.tech_contents?.map((ele3, k) => {
                                     console.log(ele3,"hii");
                                      return (
                                        (head.tech_contents[0]?.technologies !=
                                        null ? activeLang ==
                                          ele3.technologies?.id:true) && (
                                          <p
                                          key={k}
                                            id="editor"
                                            // dangerouslySetInnerHTML={{
                                            //   __html: ele3.tech_content,
                                            // }}
                                            value={text}
                                            onChange={(e) =>
                                              setText(e.target.value)
                                            }
                                            onClick={()=>{setOverflow(index)}}
                                            className={`text-white text-nowrap ${overflow == index ? 'overflow-x-auto' : ''  } mb-4 bg-gray-600 rounded-b-lg text-sm p-2`}
                                          >
                                            <pre className="codeEditor">
                                              {/* {parseContent( 
                                                ele3.tech_content
                                             )} */}
                                             <div dangerouslySetInnerHTML={{__html: ele3.tech_content}}></div>
                                            </pre>
                                          </p>
                                        )
                                      );
                                    })


                                 
                                    
                                    }
                              </div>
                            );
                          })}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  );
                })}
              </>
            );
          })}
        </div>
        <Footer
        // withoutHead={withouthead}
        setWithouthead={setWithouthead}
        dark={dark}
      />
      </div>
      
      
    
    </div>
  );
}

export default Dashboard;
