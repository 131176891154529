import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api-2.reltime.com', // Set your base URL here
  headers: {
    "ngrok-skip-browser-warning": "69420",
  }
});

export default axiosInstance;
