import { useState } from "react";
import { NavLink } from "react-router-dom";
import Side from "./Side";
import logo2 from "../../assets/reltime.png";

function Nav({
  data,
  dark,
  setDark,
  withoutHead,
  apiData,
heading,
openSide,
setOpenSide,
subheading,
  setData,
}) {
  // const [dark, setDark] = useState();
  const [open, setOpen] = useState();

  const login = localStorage.getItem("user");
  const [currData, setCurrData] = useState(data);

  const handleLogout = () => {
    localStorage.removeItem("user");
  };
  const handelSearch = (e) => {
    let apiFilterData = [...apiData];
    console.log("Data-->>",apiFilterData);
    const result1 = apiFilterData.filter((element) => {
 
       if (e.target.value === "") {
        element.isOpen=false;
              // doc.isOpen=false
         return true;
       } else if (!(element?.heading?.toLowerCase().includes(e.target.value.toLowerCase()))) {
        element.isOpen=true;
         const doc = element?.docs?.filter((doc) => {
   
           if (doc?.main_heading?.toLowerCase().includes(e.target.value.toLowerCase())) {
       
            doc.isOpen=false
             return true;
           } 
         
         else {
             const sub = doc?.sub_docs?.filter((sub) => {
              sub.isOpen=false;
              // doc.isOpen=false
              // element.isOpen=false;
               if (sub?.heading?.toLowerCase().includes(e.target.value.toLowerCase())) {
               
            doc.isOpen=true
            sub.isOpen=true
                 return true;
               }
            //    sub.isOpen=false;
            //    element.isOpen=false;
            // doc.isOpen=false
               return false;
             });
             return sub ? sub.length > 0 : null
           }
         
         });
         return doc ? doc.length > 0 : null
       }
       else{
 
         return true;
       }
     });
     
     console.log("vv",e.target.value);
     setCurrData(result1);
    
  
    
   };
  return (
    <div>
      <nav
        class={`${
          dark ? "custom-bg" : "bg-white"
        } fixed top-0 z-50 w-full  border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700`}
      >
        <div class="px-3 py-3 lg:px-5 lg:pl-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-end rtl:justify-end">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                onClick={() => {
                  setOpenSide(!openSide);
                }}
                class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span class="sr-only">Open sidebar</span>
                <svg
                  class="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              {openSide ? (
                <div
                  class="absolute top-8 left-0 z-10 mt-8 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1"
                >
                  <div
                    class={`h-screen overflow-scroll p-3 pb-20   ${
                      dark ? "custom-bg text-white" : " text-gray-500 "
                    } dark:bg-gray-800`}
                  >
                    <ul class="space-y-2 ">
                      <li>
                        <div class="relative mt-1 ms-4">
                          <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-2 pointer-events-none">
                            <svg
                              class="w-4 h-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                              />
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="search"
                            class="block p-1 ps-8 w-40 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Find anything"
                            onChange={(e) => handelSearch(e)}
                          />
                        </div>
                      </li>
                      {withoutHead?.length && withoutHead?.map((element, i) => {
            if (element.heading_id) {
              return null;
            }

            return (
              <li

                  className={`flex text-sm items-center ${
                    dark
                      ? "custom-bg text-white  hover:text-black"
                      : " text-gray-500 "
                  } rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
                  // href={`#${element.main_heading.toLowerCase().replace(" ","_")}`}
                  //onClick={handleClick(element.main_heading.toLowerCase().replaceAll(" ","_"))}
                >
                  <span class="ms-2 my-5">{element.main_heading}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
          
              </li>
            );
          })}
          {currData?.length ? currData?.map((element, index) => {
        // console.log("=>",element);
            return (
              <li
                className="my-[20px] navl"
                       
              >


              <NavLink 
                   onClick={(e) => {
                    (element.isOpen == true ? setCurrData(currData.map((data,i)=>{ if(i==index)data.isOpen=false; return data })): setCurrData(currData.map((data,i)=>{ if(i==index)data.isOpen=true; return data })));
                   // handleClick(e,element.heading.toLowerCase().replaceAll(" ","_"));
                  }}
                
               
                className={`flex active justify-between font-[400] px-2 text-sm mb-3 items-center text-bold ${
                  dark
                    ? "custom-bg text-white hover:text-black"
                    : " text-[#101010]"
                } rounded-lg  hover:bg-gray-100 dark:hover:bg-gray-700 group`}
              
              >


              
                {" "}
    
    
                  {element.heading}
            
              
                  {element.isOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="min-w-[20px] min-h-[20px] max-w-[20px] max-h-[20px]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="min-w-[20px] min-h-[20px] max-w-[20px] max-h-[20px] -rotate-90"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  )}
             </NavLink>

                {element.isOpen
                  ? element.docs?.map((doc, i) => {
                      console.log(i, "p3");
                      return (
                        <>
                          {" "}
            
        
                          <NavLink ac style={heading?.toLowerCase().replaceAll("_"," ")==doc.main_heading.toLowerCase()?{ backgroundColor:'#5f4cfe1A',padding:'5px 8px', color:'#5469D4',fontWeight:'600'}:{}} 
                            key={i}
                           onClick={() =>
                            (doc.isOpen == true ? setCurrData(currData.map((data,j)=>{ if(j==index)data.docs[i].isOpen=false; return data })): setCurrData(currData.map((data,j)=>{ if(j==index)data.docs[i].isOpen=true; return data })))
                          }
                          className={`flex text-sm items-center ml-5 mt-2 px-2 ${
                            dark
                              ? "custom-bg text-white  hover:text-black"
                              : " text-gray-500 "
                          } rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
                          to = {`/home/${doc.main_heading.toLowerCase().replaceAll(" ","_")}`}
                //  onClick={(e) => {
                //   handleClick(e,element.heading.toLowerCase().replaceAll(" ","_"))}}
                >
                   {doc.main_heading}
                   </NavLink>

                 {doc.isOpen ?
                 
                 doc.sub_docs?.map((ele, j) => {
                              
                                return (
                                  <>
                    
                <NavLink    key={j}   className={`flex text-sm items-center ml-8 mt-2 px-2 ${
                                        dark
                                          ? "bg-black text-white  hover:text-black"
                                          : " text-gray-500 "
            } rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`} style={subheading?.toLowerCase().replaceAll("_"," ")==ele.heading.toLowerCase()?{ color:'#5469D4',fontWeight:500 }:{}} 
            to = {`/home/${doc.main_heading.toLowerCase().replaceAll(" ","_")}/${ele.heading.toLowerCase().replaceAll(" ","_")}`}
    
                >
                   {ele.heading}
                   </NavLink>
                                  </>
                                );
                              })
                              : null
                            }
                        </>
                      );
                    })
                  : null}
              </li>
            );
          }):null
        }

                      {/* <li>
            <NavLink
              className={`flex text-sm items-center ${
                dark ? "bg-black text-white" : " text-gray-500 "
              } rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
            >
              <span class="ms-6">Authentication</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="flex text-sm items-center text-gray-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <span class="ms-6">Connected Accounts</span>
            </NavLink>
          </li> */}
                    </ul>
                  </div>
                </div>
              ) : null}
              {/* {openSide ? console.log("clicked") : console.log("not")} */}
              <div className="flex  justify-start">
                {" "}
                <a href="/home" class="flex ms-2 md:me-24">
                  <img src={logo2} class="h-8 me-4" alt="Realtime API" />
                  {/* <span
                    class={`self-center text-md  sm:text-xl whitespace-nowrap ${
                      dark ? "text-white" : "text-black"
                    }`}
                  >
                    Reltime API
                  </span> */}
                </a>
              </div>
            </div>
            <div className="flex justify-end gap-4">
              {/* <div class="flex items-center">
                <button type="button" class="flex  text-sm ">
                  <NavLink className="font-semibold sr-only sm:not-sr-only text-semibold text-indigo-600">
                    API Reference
                  </NavLink>
                </button>
              </div> */}
              <div class="flex items-center">
                {/* <a
                  onClick={() => setDark(!dark)}
                  class="inline-flex items-center cursor-pointer"
                >
                  <input type="checkbox" value="" class="sr-only peer" />
                  <div
                    class={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 rtl:peer-checked: after:content-[''] after:absolute after:top-[2px]  after:bg-white after:border-gray-300 after:border after:rounded-full after:transition-all dark:border-gray-600 ${
                      dark
                        ? "bg-blue-600 after:-translate-x-full after:start-[42px] after:h-5 after:w-5 "
                        : "bg-gray-200 after:-translate-x-full after:start-[22px] after:h-5 after:w-5"
                    }`}
                  ></div>
                  <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
                </a> */}


                <svg display="none" style={{height:0}}>
	<symbol id="light" viewBox="0 0 24 24">
		<g stroke="currentColor" stroke-width="2" stroke-linecap="round">
			<line x1="12" y1="17" x2="12" y2="20" transform="rotate(0,12,12)" />
			<line x1="12" y1="17" x2="12" y2="20" transform="rotate(45,12,12)" />
			<line x1="12" y1="17" x2="12" y2="20" transform="rotate(90,12,12)" />
			<line x1="12" y1="17" x2="12" y2="20" transform="rotate(135,12,12)" />
			<line x1="12" y1="17" x2="12" y2="20" transform="rotate(180,12,12)" />
			<line x1="12" y1="17" x2="12" y2="20" transform="rotate(225,12,12)" />
			<line x1="12" y1="17" x2="12" y2="20" transform="rotate(270,12,12)" />
			<line x1="12" y1="17" x2="12" y2="20" transform="rotate(315,12,12)" />
		</g>
		<circle fill="currentColor" cx="12" cy="12" r="5" />
	</symbol>
	<symbol id="dark" viewBox="0 0 24 24">
		<path fill="currentColor" d="M15.1,14.9c-3-0.5-5.5-3-6-6C8.8,7.1,9.1,5.4,9.9,4c0.4-0.8-0.4-1.7-1.2-1.4C4.6,4,1.8,7.9,2,12.5c0.2,5.1,4.4,9.3,9.5,9.5c4.5,0.2,8.5-2.6,9.9-6.6c0.3-0.8-0.6-1.7-1.4-1.2C18.6,14.9,16.9,15.2,15.1,14.9z" />
	</symbol>
</svg>
<label class="switch">
	<input class="switch__input" type="checkbox" role="switch"  onClick={() => setDark(!dark)} name="dark" />
	<svg class="switch__icon" width="24px" height="24px" aria-hidden="true">
		<use href="#light" />
	</svg>
	<svg class="switch__icon" width="24px" height="24px" aria-hidden="true">
		<use href="#dark" />
	</svg>
	<span class="switch__inner"></span>
	<span class="switch__inner-icons">
		<svg class="switch__icon" width="24px" height="24px" aria-hidden="true">
			<use href="#light" />
		</svg>
		<svg class="switch__icon" width="24px" height="24px" aria-hidden="true">
			<use href="#dark" />
		</svg>
	</span>
	<span class="switch__sr">Dark Mode</span>
</label>
                {/* <button type="button" class="flex text-sm">
                  <NavLink
                    to="/home"
                    className={`font-semibold sr-only sm:not-sr-only text-semibold  ${
                      dark ? "text-white" : "text-indigo-600"
                    } `}
                  >
                    Docs
                  </NavLink>
                </button> */}
              </div>
              {/* <div class="flex items-center">
                <button type="button" class="flex text-sm">
                  <NavLink
                    to="/faq"
                    className={`font-semibold sr-only sm:not-sr-only text-semibold  ${
                      dark ? "text-white" : "text-indigo-600"
                    } `}
                  >
                    FAQ
                  </NavLink>
                </button>
              </div> */}
              <div class="flex items-center">
                <div class="flex items-center ms-3">
                  <div>
                    <button
                      type="button"
                      class="flex text-sm text-semibold"
                      aria-expanded="false"
                      data-dropdown-toggle="dropdown-user"
                    >
                      {login ? (
                        <NavLink
                          onClick={handleLogout}
                          className={`pr-4 font-semibold  ${
                            dark ? "text-white" : "text-indigo-600"
                          } `}
                          to="/"
                        >
                          Sign Out
                        </NavLink>
                      ) : (
                        <NavLink
                          className={`pr-4 font-semibold  ${
                            dark ? "text-white" : "text-indigo-600"
                          } `}
                          to="/"
                        >
                          Sign in
                        </NavLink>
                      )}
                    </button>
                  </div>
                  <div
                    class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                    id="dropdown-user"
                  >
                    <div class="px-4 py-3" role="none">
                      <p
                        class="text-sm text-gray-900 dark:text-white"
                        role="none"
                      >
                        Neil Sims
                      </p>
                      <p
                        class="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                        role="none"
                      >
                        neil.sims@flowbite.com
                      </p>
                    </div>
                    <ul class="py-1" role="none">
                      <li>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                          role="menuitem"
                        >
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                          role="menuitem"
                        >
                          Settings
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                          role="menuitem"
                        >
                          Earnings
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                          role="menuitem"
                        >
                          Sign out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Nav;
