import React from "react";
import { NavLink } from "react-router-dom";

const Footer = ({ dark }) => {
  return (
    <footer
      class={`${
        dark ? "custom-bg" : "bg-white"
      }  z-1000 w-full  border-t border-gray-200 bottom-0 shadow dark:bg-gray-800`}
    >
      <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span
          class={`text-sm  ${
            dark ? "text-white font-semibold" : "font-semibold text-black"
          } sm:text-center dark:text-gray-400`}
        >
          © 2024{" "}
          <a
            class={` hover:underline  ${
              !dark ? "text-black" : "text-white"
            }`}
          >
            RELTIME
          </a>
          . All Rights Reserved.
        </span>
        <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <NavLink
              to="/faq"
              className={`font-semibold    ${
                !dark ? "text-black" : "text-white"
              } `}
            >
              FAQ
            </NavLink>
          </li>
        </ul>
      </div>
    </footer>

    // <footer>
    //   <div class="h-20">
    //     <button type="button" class="flex text-sm">
    //       <NavLink
    //         to="/faq"
    //         className={`font-semibold sr-only sm:not-sr-only text-semibold  ${
    //           dark ? "text-white" : "text-indigo-600"
    //         } `}
    //       >
    //         FAQ
    //       </NavLink>
    //     </button>
    //   </div>
    // </footer>
  );
};

export default Footer;
