import { useState } from "react";
import { Link, useNavigate} from "react-router-dom";
import axios from "axios";
import axiosInstance from "../../axiosInstance";
import logo2 from "../../assets/reltime.png";

function Login({ setShowNavbar }) {
  setShowNavbar(false);
  const [sso, setSso] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    await axiosInstance
      .post(
        `/api/user/login`,
        {
          email,
          password,
        }
      )
      .then((res) => {
        console.log(res);
        localStorage.setItem("user", "login");
        navigate("/home");
      })
      .catch((err) => {});
  };
  return (
    <div
      style={{ background: `rgb(1,2,30)` }}
      class="flex  h-screen flex-col   justify-center px-4 py-12 lg:px-8 "
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-lg ">
        <div className="flex items-center">
          <img src={logo2} className="w-80 p-5  " />
          {/* <h1 className="text-xl text-center text-white p-2 font-semibold">
            Reltime
          </h1> */}
        </div>

        <div class="sm:mx-auto sm:w-full sm:max-w-lg bg-white rounded  border shadow-lg">
          <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-800">
              Sign in to your account
            </h2>
          </div>
          <form class="space-y-6 p-10" onSubmit={handleLogin}>
            <div>
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div class="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  placeholder="name@company.com"
                  onChange={(e) => setEmail(e.target.value)}
                  //   required
                  class="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {sso ? (
              <>
                <div>
                  <div class="flex items-center justify-between">
                    <label
                      for="password"
                      class="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div class="text-sm">
                      {/* <Link
                        to={"/forget"}
                        class="font-semibold text-indigo-600 hover:text-indigo-500"
                      >
                        Forgot password?
                      </Link> */}
                    </div>
                  </div>
                  <div class="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autocomplete="current-password"
                      placeholder="**********"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      class="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </>
            ) : null}

            <div>
              <button
                type="submit"
                class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Continue
              </button>
            </div>
            {/* <p class="my-4 text-sm text-center text-gray-500 px-2">
              <button
                onClick={() => setSso(!sso)}
                class="font-semibold text-md leading-6 px-2 text-indigo-600 hover:text-indigo-500"
              >
                {sso
                  ? "Use single sign-on (SSO) instead"
                  : "Use your password instead"}
              </button>
            </p> */}

            <p class="my-4 text-center text-sm text-gray-500 px-2">
              Not a member?
              <Link
                to={"/signup"}
                class="font-semibold leading-6 px-2 text-indigo-600 hover:text-indigo-500"
              >
                Sign Up
              </Link>
            </p>
          </form>
        </div>

        {/* <p className="flex mx-2 font-semibold justify-between items-center text-center w-fit  rounded p-2 text-sm text-gray-500 bg-gray-200 ">
          <span className="text-center align-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
              />
            </svg>
          </span>{" "}
          Security tip
        </p>
        <p className="p-2 text-sm text-gray-500 ">
          Bookmark this page and only use the bookmark to sign in to avoid
          phishing attempts. Phishing websites pretend to be Stripe to access
          your password.
        </p>
        <div className="flex px-2 items-center">
          <p className=" text-sm text-gray-500">Is this tip helpful?</p>
          <button className="flex gap-1 text-sm text-gray-500 p-1 mx-1 border rounded">
            Yes
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                />
              </svg>
            </span>
          </button>
          <button className="flex gap-1 text-sm text-gray-500 p-1 mx-1 border rounded">
            No
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                />
              </svg>
            </span>
          </button>
        </div>
       <p className="tooltip border p-2 rounded bg-white items-center w-fit text-gray-500">
          Thanks for your feedback
        </p>  */}

        {/* <ul className="my-2 px-2 flex gap-3 font-semibold text-sm text-gray-500">
          <li>&copy; Realtime API</li>
          <li>Contact</li>
          <li>Privacy & Terms</li>
        </ul> */}
      </div>
    </div>
  );
}

export default Login;
