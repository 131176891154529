import logo from "./logo.svg";
import "./App.css";
import {BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import Login from "./Components/Profile/Login";
import Register from "./Components/Profile/Register";
import Dashboard from "./Components/Dashboard";
import FAQ from "./Components/FAQ";
import Nav from "./Components/include/Nav";
import Footer from "./Components/include/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "./axiosInstance";
// import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";

function App() {
  const [showNavbar, setShowNavbar] = useState(true);
  const [dark, setDark] = useState();
  const [data, setData] = useState();
  const [withouthead, setWithouthead] = useState();
  const [apiData, setApiData] = useState([]);
  const [loader,setLoader]=useState(false)
  const {heading,subheading}=useParams()
  const [lang, setlang] = useState()
  const [openSide, setOpenSide] = useState(false);
  const APIData =
    "/api/getdata";
  useEffect(() => {
    axiosInstance
      .get(APIData)
      
      .then((res) => {
        axiosInstance.get("/api/tech/get")
        .then((e) => {
         
          setlang(e.data.data[0].id)
          setData(res.data.docs);
          setWithouthead(res.data.withoutHeading);
          setApiData(res.data.docs);
          // console.log(res.data.data);
        })
        .catch((err) => console.log(err));
       
        // const time=setTimeout(()=>{
        //   setLoader(false)
        //       },3000)
        //       return ()=>{
        //   clearTimeout(time)
        //       }
        
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
   {loader?<>
   <div className="h-screen overflow-hidden w-full bg-black flex justify-center items-center">
   <div class="flex justify-center items-center h-screen">
    <div class="relative w-24 h-24 animate-spin rounded-full bg-gradient-to-r from-[#c9c9c9] via-[#00ccff] to-[#0077ff] ">
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 bg-black rounded-full border-2 border-black"></div>
    </div>
</div>
   </div>
   </>
   :
    <div
      className={`${dark ? "!custom-bg text-white" : "bg-white text-black"} `}
    >
      {showNavbar&&data && (
        <Nav
          data={data}
          withoutHead={withouthead}
          setWithouthead={setWithouthead}
          dark={dark}
          openSide={openSide}
          setOpenSide={setOpenSide}
          heading={heading}
          subheading={subheading}
          apiData={apiData}
          setApiData={setApiData}
          setDark={setDark}
          setData={setData}
        />
      )}
      <Routes>
        <Route 
          path="/"
          element={<Login setShowNavbar={setShowNavbar} />}
        />
        <Route
          path="/signup"
          element={<Register setShowNavbar={setShowNavbar} />}
        />
        <Route 
          path="/home/:heading/:subheading"
          element={ data&&<Dashboard
            withoutHead={withouthead}
            setOpenSide={setOpenSide}
            setWithouthead={setWithouthead}
            setShowNavbar={setShowNavbar}
            data={data}
            lang={lang}
            heading={heading}
            subheading={subheading}
            apiData={apiData}
            setApiData={setApiData}
            dark={dark}
            setData={setData}
          />
            
          }
        />
        <Route 
          path="/home/:heading"
          element={ data&&<Dashboard
            withoutHead={withouthead}
            setWithouthead={setWithouthead}
            setShowNavbar={setShowNavbar}
            data={data}
            setOpenSide={setOpenSide}
            heading={heading}
            lang={lang}
            subheading={subheading}
            apiData={apiData}
            setApiData={setApiData}
            dark={dark}
            setData={setData}
          />
          }
        />
        <Route 
          path="/home"
          element={ data&&<Dashboard
            withoutHead={withouthead}
            setWithouthead={setWithouthead}
            setShowNavbar={setShowNavbar}
            data={data}
            setOpenSide={setOpenSide}
            heading={heading}
            lang={lang}
            subheading={subheading}
            apiData={apiData}
            setApiData={setApiData}
            dark={dark}
            setData={setData}
          />
            
          }
        />

      
        <Route path="/faq" element={<FAQ dark={dark} />}/>
      </Routes>
      
    </div>
    }
    </>
  );
}

export default App;
