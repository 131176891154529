import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/logo.png";
import ReactFlagsSelect from "react-flags-select";
import logo2 from "../../assets/reltime.png";
import axiosInstance from "../../axiosInstance";

function Register({ setShowNavbar }) {
  setShowNavbar(false);
  const [selected, setSelected] = useState("");

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    await axiosInstance
      .post(
        `/api/user/register`,
        {
          name,
          email,
          // mobile,
          password,
        }
      )
      .then((res) => {
        console.log(res);
        navigate("/");
        // alert("Thank you for registering...");
      })
      .catch((err) => console.log(err));
    // alert("password is matched")
    // alert("password and confirm password is not matched");
  };

  return (
    <div
      style={{ background: `rgb(1,2,30)` }}
      class="md:flex  h-screen  md:py-4"
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-lg ">
        <div className="flex items-center">
          <img src={logo2} className="w-80 p-5  " />
          {/* <h1 className="text-xl text-center text-white p-2 font-semibold">
            Reltime
          </h1> */}
        </div>
        {/* <img src={logo} className="w-24 p-2 " /> */}
        {/* <div class="sm:mx-auto sm:w-full sm:max-w-lg  rounded ">
        <h1 className="text-xl p-2 sr-only  sm:not-sr-only font-semibold">
          Realtime API
        </h1>

        <div class="sm:mx-auto sr-only sm:not-sr-only p-2 sm:w-full sm:max-w-lg  ">
          <div class="flex items-center gap-4 sm:mx-auto sm:w-full sm:max-w-sm">
            <p className="text-indigo-600">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </p>
            <div>
              <h3 class=" text-md font-bold leading-9 tracking-tight text-gray-800">
                Get started quickly
              </h3>
              <p className="text-sm text-gray-500">
                Integrate with developer-friendly APIs or choose low-code or
                pre-built solutions.
              </p>
            </div>
          </div>
          <div class="flex justify-between items-center gap-4 sm:mx-auto sm:w-full sm:max-w-sm">
            <p className="text-indigo-600">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </p>
            <div>
              <h3 class=" text-md font-bold leading-9 tracking-tight text-gray-800">
                Support any business model
              </h3>
              <p className="text-sm text-gray-500">
                E-commerce, subscriptions, SaaS platforms, marketplaces, and
                more—all within a unified platform.
              </p>
            </div>
          </div>
          <div class="flex justify-between items-center gap-4 sm:mx-auto sm:w-full sm:max-w-sm">
            <p className="text-indigo-600">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </p>
            <div>
              <h3 class=" text-md font-bold leading-9 tracking-tight text-gray-800">
                Join millions of businesses
              </h3>
              <p className="text-sm text-gray-500">
                Realtime API is trusted by ambitious startups and enterprises of
                every size.
              </p>
            </div>
          </div>

          <div className="mt-32 flex justify-between items-center gap-4 sm:mx-auto sm:w-full sm:max-w-sm">
            <ul className="flex gap-3 font-semibold text-sm text-gray-500">
              <li>&copy; Realtime API</li>
              <li>Contact</li>
              <li>Privacy & Terms</li>
            </ul>
          </div>
        </div>
      </div> */}

        <div class="sm:mx-auto sm:w-full sm:max-w-lg  rounded ">
          <div class="sm:mx-auto sm:w-full sm:max-w-lg bg-white rounded  border shadow-lg">
            <div class="sm:mx-auto sm:w-full sm:max-w-sm">
              <h2 class="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-gray-800">
                Create your account
              </h2>
            </div>
            <form class="space-y-6 p-10" onSubmit={handleRegister}>
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div class="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    placeholder="name@company.com"
                    onChange={(e) => setEmail(e.target.value)}
                    //   required
                    class="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Full Name
                </label>
                <div class="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Full name"
                    onChange={(e) => setName(e.target.value)}
                    required
                    class="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {/* <div>
              <label
                for="mobile"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                Mobile Number
              </label>
              <div class="mt-2">
                <input
                  id="mobile"
                  name="mobile"
                  type="number"
                  placeholder="+00-0000000"
                  onChange={(e) => setMobile(e.target.value)}
                  required
                  class="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              
              </div>
            </div> */}

              <div>
                <div class="flex items-center justify-between">
                  <label
                    for="password"
                    class="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                </div>
                <div class="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    placeholder="**********"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    class="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Create Account
                </button>
              </div>
              <p class="my-4 text-center text-sm text-gray-500 px-2">
                Have an account?
                <Link
                  to={"/"}
                  class="font-semibold leading-6 px-2 text-indigo-600 hover:text-indigo-500"
                >
                  Sign In
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
